import "instantsearch.css/themes/algolia-min.css";
import React, { useState } from "react";
import { TfiShoppingCart } from "react-icons/tfi";
import {
  InstantSearch,
  Hits,
  SortBy,
  SearchBox,
  Pagination,
  ClearRefinements,
  RefinementList,
  HierarchicalMenu,
  RangeInput,
  Breadcrumb,
  Stats,
  CurrentRefinements,
  Index,
} from "react-instantsearch-dom";
import "./App.css";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

const searchClient = instantMeiliSearch(
  "https://testsearch.webair.it:7700",
  "1206dc1545cc270cff49ba527b7d29389bd70e15ae5912986c2b5c23c6ee5b7a",
  {
    finitePagination: true
  }
);

function App() {

  const [tab, setTab] = useState('tab1');

  return (
    <div className="ais-InstantSearch rounded-2xl p-2">
      <InstantSearch indexName={tab === 'tab1' ? "trattore" : "trattorep"} searchClient={searchClient}>

        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class={tab === 'tab1' ? "nav-link active" : "nav-link"} onClick={() => setTab('tab1')} aria-current="page">Articoli</a>
          </li>
          <li class="nav-item">
            <a class={tab === 'tab2' ? "nav-link active" : "nav-link"} onClick={() => setTab('tab2')}>Modelli</a>
          </li>
        </ul>

        {tab === 'tab1' &&
          <>
            <SearchBox showLoadingIndicator searchAsYouType={true} />
            <Index indexName="trattore">
              <div className="left-panel">
                <Stats />
                <ClearRefinements />
                <SortBy
                  defaultRefinement="trattore"
                  items={[
                    { value: "trattore", label: "Default" },
                    {
                      value: "trattore:price:desc",
                      label: "Highest Price"
                    },
                    {
                      value: "trattore:price:asc",
                      label: "Lowest Price"
                    }
                  ]}
                />

                {/* <h2 className="text-lg text-bold mb-1 mt-3">Category</h2>
        <HierarchicalMenu
          attributes={[
            'categoryit.lv1',
            'categoryit.lv2',
            'categoryit.lv3',
            'categoryit.lv4',
          ]}
        /> */}

                <h2 className="text-lg text-bold mb-1 mt-3">Marca</h2>
                <RefinementList attribute="brand" limit={10} operator="or" showMore showMoreLimit={100} />

                <h2 className="text-lg text-bold mb-1 mt-3">Prezzo</h2>
                <RangeInput
                  attribute="price"
                />
              </div>
              <div className="right-panel pl-16">
                <CurrentRefinements
                // transformItems={items =>
                //   items.filter(item => item.attribute !== 'categoryit.lv1')
                // } 
                />

                <Hits hitComponent={Hit} className="mt-2 grid" />
                <Pagination showLast={true} />
              </div>
            </Index>
          </>
        }

        {tab === 'tab2' &&
          <>
            <SearchBox showLoadingIndicator searchAsYouType={true} />
            <Breadcrumb
              attributes={[
                'category.lv1',
                'category.lv2',
                'category.lv3',
                'category.lv4',
                'category.lv5',
              ]}
            />

            <Index indexName="trattorep">
              <div className="left-panel">
                <Stats />
                <ClearRefinements />
                <SortBy
                  defaultRefinement="trattorep"
                  items={[
                    { value: "trattorep", label: "Default1" },
                  ]}
                />

                <h2 className="text-lg text-bold mb-1 mt-3">Category</h2>
                <HierarchicalMenu
                  attributes={[
                    'category.lv1',
                    'category.lv2',
                    'category.lv3',
                    'category.lv4',
                    'category.lv5',
                  ]}
                />

              </div>
              <div className="right-panel pl-16">
                <CurrentRefinements
                  transformItems={items =>
                    items.filter(item => item.attribute !== 'category.lv1')
                  }
                />

                <Hits hitComponent={Hit2} className="mt-2 grid" />
                <Pagination showLast={true} />
              </div>
            </Index>
          </>
        }

      </InstantSearch>
    </div>
  );
}

const Hit = ({ hit }) => (

  <div key={hit.id} class="flex flex-col p-1 group w-full justify-between bg-white" >
    <div>
      <h3 className="text-xs text-bold tracking-tight text-end">
        {hit.brand ? hit.brand.toUpperCase() : ''}
      </h3>
      <div className="flex flex-col aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-white lg:aspect-none h-48 p-1">
        <img
          src={hit?.thumbnail}
          //alt={hit?.titoloit}
          className="h-full w-full object-contain object-center lg:h-full lg:w-full group-hover:opacity-75"
        />
      </div>
    </div>
    <div className="mt-1 flex items-end bg-white justify-between flex-end">
      <div class="">
        <p className="text-sm font-semibold text-gray-700 overflow-hidden line-clamp-2">
          {hit.titolo}
        </p>
        <p className="text-xs font-light text-gray-900 line-clamp-1">OEM: {hit.codice}</p>
        <p className="text-lg font-medium text-green-700">€{hit.price}</p>
      </div>
      <button class="rounded-full bg-green-600 p-2 text-white mb-0 self-end"><TfiShoppingCart /></button>
    </div>


  </div>

);

const Hit2 = ({ hit }) => (
  <div key={hit.id} class="flex flex-col p-1 group w-full justify-between bg-white" >
    <div>
      <h3 className="text-xs text-bold tracking-tight text-end">
        {hit.brand ? hit.brand.toUpperCase() : ''}
      </h3>
      <div className="flex flex-col aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-white lg:aspect-none h-48 p-1">
        <img
          src={hit?.thumbnail}
          //alt={hit?.titoloit}
          className="h-full w-full object-contain object-center lg:h-full lg:w-full group-hover:opacity-75"
        />
      </div>
    </div>
    <div className="mt-1 flex items-end bg-white justify-between flex-end">
      <div class="">
        <p className="text-sm font-semibold text-gray-700">
          {hit.titolo}
        </p>
      </div>
    </div>


  </div>

);

export default App;
